import Vue from "vue";


if(document.getElementById('app-social')) {

    window.erecruterApp = new Vue({
        el: '#app-social',
        data: {
            settings: {
                // url: 'https://s-api.dlv.pl',
                url: 'https://apikariera.suus.com',
                key: 'qfasg-4g73d-0kfewa-1dy6nc',
                loaderClassName: 'lds-ripple',
                loaderVisibleClassName: 'is-visible',
            },
            items: [],
            fbItems: [],
            instagramItems: [],
        },

        mounted: function(){
            // this.request("/api/fb/feeds/1/" + this.settings.key + "?limit=5",  (data) => {
            this.request("/api/fb/feeds/1/" + this.settings.key + "?limit=8",  (data) => {
                this.fbItems = data;
/*
                this.request("/api/instagram/media/1/" + this.settings.key + "?limit=5",  (data) => {
                    this.instagramItems = data;

                    if(this.instagramItems.length){
                        this.instagramItems.forEach((x)=>{
                            x.type = "instagram";
                        })
                    }

                    if(this.fbItems.length){
                        this.fbItems.forEach((x)=>{
                            x.type = "fb";
                            this.items.push(x);
                            if(this.instagramItems.length){
                                this.items.push(this.instagramItems.shift())
                            }
                        })
                    } else {
                        this.items = this.instagramItems;
                    }
                });
 */

                this.fbItems.forEach((x)=>{
                    x.type = "fb";
                    this.items.push(x);
                })

            });
        },
        methods: {
            showPreloader: function() {
                if (this.settings.loaderClassName) {
                    this.$el.getElementsByClassName(this.settings.loaderClassName)[0].classList.add(this.settings.loaderVisibleClassName);
                }
            },
            hidePreloader: function() {
                if (this.settings.loaderClassName) {
                    this.$el.getElementsByClassName(this.settings.loaderClassName)[0].classList.remove(this.settings.loaderVisibleClassName);
                }
            },
            request: function (url, callback) {
                var request = new XMLHttpRequest();
                var that = this;
                that.showPreloader();
                // that.hideList();
                request.onreadystatechange = function() {
                    if(request.readyState === 4 && request.status === 200) {
                        that.hidePreloader();
                    } else {
                        that.showPreloader();
                    }
                }

                request.open('GET', this.settings.url + url, true);

                request.onload = function() {
                    if (this.status >= 200 && this.status < 400) {
                        callback(JSON.parse(this.response))
                    }
                };
                request.send();
            }
        }
    });


}
